import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { TextField } from 'redux-form-material-ui'

import classNames from 'classnames'

import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const UserLoginForm = ({ handleSubmit, submitting, theme }) => {
  return (
    <div className="formWrapper login">
      <form className={classNames('container', { 'disabled': submitting })} onSubmit={ handleSubmit }>
        <Field
          name="email"
          label="Email Address"
          component={ TextField }
          className="formGroup"
        />
        <Field
          name="password"
          label="Password"
          component={ TextField }
          type="password"
          className="formGroup"
        />
        <Button type="submit" variant="contained" className="button" style={theme.button.primary}>Login</Button>
      </form>
    </div>
  )
}

export default compose(withStyles(null, { withTheme: true }), reduxForm({ form: 'userLogin' }))(UserLoginForm)
