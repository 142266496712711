import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'

import Loading from '../components/Loading'
import { TextField } from 'redux-form-material-ui'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const UserResetPasswordForm = ({ handleSubmit, submitting, theme, error }) => {
  if (submitting) {
    return (<Loading />)
  }
  return (
    <div>
      <p>New password must:</p>
      <ul>
        <li>Be a minimum of 8 characters</li>
        <li>Contain 1 uppercase letter</li>
        <li>Contain 1 lowercase letter</li>
      </ul>
      <form onSubmit={ handleSubmit }>
        <Field
          name="password"
          label="Password"
          component={TextField}
          type="password"
          className="formGroup"
        />
        <Field
          name="confirmPassword"
          label="Confirm Password"
          component={TextField}
          type="password"
          className="formGroup"
        />

        { error && <div className="inlineError">{ error }</div> }

        <Button type="submit" variant="contained" className="button" style={theme.button.primary}>Reset Password</Button>

      </form>
    </div>
  )
}

export default compose(withStyles(null, { withTheme: true }), reduxForm({ form: 'userResetPasswordForm' }))(UserResetPasswordForm)
