import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { TextField } from 'redux-form-material-ui'

import Loading from '../components/Loading'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const UserForgotPasswordForm = ({ theme, handleSubmit, submitting }) => {
  if (submitting) {
    return (<Loading>Finding your account...</Loading>)
  }
  return (
    <form onSubmit={ handleSubmit }>
      <Field
        name="email"
        label="Email address"
        component={TextField}
        className="formGroup"
      />
      <Button type="submit" variant="contained" className="button" style={theme.button.primary}>Reset Password</Button>
    </form>
  )
}

export default compose(withStyles(null, { withTheme: true }), reduxForm({ form: 'userForgotPassword' }))(UserForgotPasswordForm)
