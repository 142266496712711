import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { TextField } from 'redux-form-material-ui'

import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Loading from '../components/Loading'

const UserChangePasswordForm = ({ theme, handleSubmit, error, isLoading }) => {
  return (
    <div className={classNames('formWrapper', 'changePassword', { 'isLoading': isLoading })}>
      {
        isLoading &&
        <Loading inline={true}>Changing your password...</Loading>
      }

      <p>New password must:</p>
      <ul>
        <li>Be a minimum of 8 characters</li>
        <li>Contain 1 uppercase letter</li>
        <li>Contain 1 lowercase letter</li>
      </ul>

      <form onSubmit={ handleSubmit }>
        <Field
          name="oldPassword"
          label="Old Password"
          component={TextField}
          type="password"
          className="formGroup"
        />
        <Field
          name="password"
          label="New Password"
          component={TextField}
          type="password"
          className="formGroup"
        />
        <Field
          name="confirmPassword"
          label="Confirm New Password"
          component={TextField}
          type="password"
          className="formGroup"
        />

        { error && <div className="inlineError">{ error }</div> }

        <Button type="submit" variant="contained" className="button" style={theme.button.primary}>Change Password</Button>

      </form>
    </div>
  )
}

export default compose(withStyles(null, { withTheme: true }), reduxForm({ form: 'userChangePasswordForm' }))(UserChangePasswordForm)
