import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import { login, redirectTo } from '../actions'
import { showSnack } from 'common/actions'

import Loading from './Loading'
import UserLoginForm from '../forms/UserLoginForm'
import { SubmissionError } from 'redux-form'

import { withStyles } from '@material-ui/core/styles'

const mapStateToProps = ({ currentUser, destination }) => ({ currentUser, destination })
const mapDispatchToProps = { login, redirectTo, showSnack }

class UserLogin extends Component {
  state = {
    isLoading: false
  }

  componentDidMount () {
    // Don't show this page if the user is logged in. <UserLogin /> shouldn't be rendered until
    // after we've initialized the current user.
    if (this.props.currentUser) {
      this.redirect()
    }
  }

  submit = async ({ email, password }) => {
    this.setState({ isLoading: true })
    try {
      await this.props.login(email, password)
      this.redirect()
    }
    catch (err) {
      this.props.showSnack(err.message)
      this.setState({ isLoading: false })
      throw new SubmissionError({ _error: err.message })
    }
  }

  // Don't use <Redirect /> in UserLogin because we may be redirecting externally.
  // This avoids a flash of the user profile form while the browser redirects
  redirect = () => {
    if (this.props.destination.hasOwnProperty('url')) {
      this.props.redirectTo(this.props.destination.url)
    }
    else {
      this.props.history.push('/user/profile')
    }
  }

  render () {
    const { theme } = this.props

    if (this.state.isLoading) {
      return (
        <Loading inline={true}>Logging you in...</Loading>
      )
    }
    return (
      <div className="container">
        <UserLoginForm onSubmit={ this.submit } />
        <footer>
          {/* <Link to="/user/register" style={{ color: theme.palette.primary.main }}>Register for AllCore360</Link> */}
          <Link to="/user/password" style={{ color: theme.palette.primary.main }}>Forgot Password?</Link>
        </footer>
      </div>
    )
  }
}

export default compose(withStyles(null, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(UserLogin)
